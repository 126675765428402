.toolbar {
  box-sizing: border-box;
  background: #fff;
  color: #000;
  border: none;
  position: fixed;
  min-width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  height: 55px;
}

.toolbar__moeicon {
  display: inline-block;
  padding: 15px 10px 10px 10px;
  background: black;
}

.toolbar__title {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 100;
  border: 0px;
  width: 500px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.toolbar__title:hover {
  background: #f7f7f7;
}

.toolbar__title:focus {
  outline: 0;
}

.toolbar__last {
  display: flex;
}

.toolbar-accordion-container {
  width: 100%;
  display: flex;
  justify-content: left;
  position: relative;
}
.toolbar-accordion {
  position: absolute;
  background-color: #f7f7f7;
  width: 100%;
}

.toolbar-accordion__description {
  max-height: 75vh;
  overflow: scroll;
  padding: 20px;
  z-index: 500;
}

@media print {
  .toolbar {
    display: none;
  }
}

[data-reach-menu-popover] {
  z-index: 9;
}
