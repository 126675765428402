$card-height: 190px;

.boardcard {
  margin: 8px;
  // DEV NOTE:
  // Don't set 'padding' because react-beautiful-dnd will
  // miscalculate the card height during dragging
  padding: 0px;
  border: 4px solid transparent;
  border-radius: 0.3rem;

  flex: 0 0 320px;
  max-height: $card-height;
  height: $card-height;
  background: white;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow 1s;
  position: relative;

  @media print {
    flex: 0 0 320px;
    border: 1px solid lightgrey;
    border-width: 4px;
    font-size: 1rem;
    vertical-align: top;
    width: 14rem;
    margin: 20px;
  }
}
.boardcard__print-description {
  display: none;
  @media print {
    display: block;
    padding: 0.5rem 0.7rem;
  }
}

@mixin set-border($color) {
  border: 4px solid $color;
}

.border--red {
  @include set-border(red);
}
.border--green {
  @include set-border(green);
}
.border--blue {
  @include set-border(blue);
}
.border--yellow {
  @include set-border(yellow);
}
.border--cyan {
  @include set-border(cyan);
}

.border--purple {
  @include set-border(#8b008b);
}
.border--grey {
  @include set-border(#4b6a88);
}
.border--orange {
  @include set-border(#bb671c);
}

.boardcard__label {
  padding: 0.5rem 0.7rem;
  position: absolute;
  background-color: white;
  z-index: 1;
  @media print {
    position: relative;
  }
}

.boardcard--dragging {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-duration: 0.75s;
  animation-name: bounceIn;
}
