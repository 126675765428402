.gutters {
  padding-left: 22px;
  padding-right: 22px;
}

.gutter-left {
  padding-left: 12px;
}
.gutter-right {
  padding-right: 12px;
}
.summary {
  background: white;
  min-height: 100vh;
  padding-top: 90px;
  padding-left: 40px;

  padding-right: 40px;
}

.summary__row {
  margin-bottom: 30px;
}

.summary-list {
  background: #eeeeee;
  margin-left: 6px;
  margin-right: 3px;
  &:last-of-type {
    margin-right: 6px;
  }
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.summary-list__title {
  text-align: center;
  background: #c0c0c0;
  min-height: 39px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
}
.summary-list__cards {
  padding-top: 16px;
}
.summary-list__card {
  border: 3px solid white;
  margin-bottom: 12px;
  background: #ffffff;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.summary__title {
  text-align: center;
}
