.upload-message {
  p {
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
  }
}
.upload-message__files {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.upload-message__file-name {
  flex: 1;
  margin: 0;
  display: flex;
  // justify-content: center;
  // align-items: center;
  text-align: left;
  max-width: 85%;
  font-size: 0.8rem;
  &::before {
    margin-right: 0.7em;
    width: 16px;
    height: 20px;
    content: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8.586C8.8512 1.00006 9.10551 1.10545 9.293 1.293L14.707 6.707C14.8946 6.89449 14.9999 7.1488 15 7.414V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19Z' stroke='%2318181B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
.upload-message__file-delete {
  float: right;
}

.upload-message__file-error {
  color: red;
}
.upload-message__fake-button {
  display: block;
  border: 3px solid black;
  background: white;
  color: black;
  padding: 0.7em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    margin-right: 0.3em;
    width: 9px;
    height: 15px;
    content: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L4.5 1.5M4.5 1.5L8 5M4.5 1.5L4.5 13.5' stroke='%2318181B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
.buttons-tray {
  display: flex;
  flex-direction: row;
}
.buttons-tray__cancel {
  flex: 1;
}
.buttons-tray__upload {
  // align-self: flex-end;
}
.drop-message {
  margin: 0;
  padding: 0;
}
.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  min-height: 100px;
  width: 100%;
  border: 3px dashed black;
  border-radius: 5px;
  padding: 90px;
  cursor: pointer;
  margin-bottom: 20px;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  // color: #ff6e40;
  // box-shadow: 0 0 13px 3px #ff6e40;
}
