.boardmodalCard__label {
  font-size: 20px;
}
.boardmodalCard__label--error {
  border: 1px solid red;
}

.boardmodalCard__buttontray {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: 1em;
  padding: 1em 0;
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
