// Set the number of columns you want to use on your layout.
$flexboxgrid-columns: 12 !default;
// Set the gutter between columns.
$flexboxgrid-gutter-width: 0 !default;

$breakpoints: sm $breakpoint-small, md $breakpoint-medium, lg $breakpoint-large,
  xl $breakpoint-xlarge;

$gutter-compensation: $flexboxgrid-gutter-width * 0.5 * -1;
$half-gutter-width: $flexboxgrid-gutter-width * 0.5;

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;

  //hey dev!
  // if you want to do different gutter size by breakpoint, do it here.
  // probably via separate vars, e.g. $flexboxgrid-gutter-width-md, $flexboxgrid-gutter-width-lg etc
}

$name: xs;
.col-#{$name} {
  @include flexboxgrid-sass-col-common;
  flex-basis: auto;
}
@for $i from 1 through $flexboxgrid-columns {
  .col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    flex-basis: 100% / $flexboxgrid-columns * $i;
    max-width: 100% / $flexboxgrid-columns * $i;
  }
}
@for $i from 1 through $flexboxgrid-columns {
  .col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;
    margin-left: 100% / $flexboxgrid-columns * $i;
  }
}
.col-#{$name} {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.start-#{$name} {
  justify-content: flex-start;
  text-align: start;
}

.center-#{$name} {
  justify-content: center;
  text-align: center;
}

.end-#{$name} {
  justify-content: flex-end;
  text-align: end;
}

.top-#{$name} {
  align-items: flex-start;
}

.middle-#{$name} {
  align-items: center;
}

.bottom-#{$name} {
  align-items: flex-end;
}

.around-#{$name} {
  justify-content: space-around;
}

.between-#{$name} {
  justify-content: space-between;
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}

@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  @media only screen and (min-width: $size) {
    .col-#{$name} {
      @include flexboxgrid-sass-col-common;
      flex-basis: auto;
    }
    @for $i from 1 through $flexboxgrid-columns {
      .col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common;
        // @include flex-basis(100% / $flexboxgrid-columns * $i);
        flex-basis: 100% / $flexboxgrid-columns * $i;
        max-width: 100% / $flexboxgrid-columns * $i;
      }
    }
    @for $i from 1 through $flexboxgrid-columns {
      .col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common;
        margin-left: 100% / $flexboxgrid-columns * $i;
      }
    }
    .col-#{$name}-offset-0 {
      @include flexboxgrid-sass-col-common;
      margin-left: 0;
    }
    .col-#{$name} {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
    .start-#{$name} {
      justify-content: flex-start;
      text-align: start;
    }

    .center-#{$name} {
      justify-content: center;
      text-align: center;
    }

    .end-#{$name} {
      justify-content: flex-end;
      text-align: end;
    }

    .top-#{$name} {
      align-items: flex-start;
    }

    .middle-#{$name} {
      align-items: center;
    }

    .bottom-#{$name} {
      align-items: flex-end;
    }

    .around-#{$name} {
      justify-content: space-around;
    }

    .between-#{$name} {
      justify-content: space-between;
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }
  }
}
