.field__textarea {
  border-width: 0px;
  font-family: inherit;
  min-height: 200px;
  width: 100%;
}
.field__textarea--readonly {
  height: 200px;
  overflow: scroll;
}

.field__textarea--noresize {
  resize: none;
}
.field__textarea-container {
  border: solid 1px black;
  height: 200px;
  overflow: scroll;
}
