//IE11 hack so tool bar menu buttons look better
.no-flexbox .toolbar__last {
  min-width: 450px;
}

// CSS specific to iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  // for plus button alignment
  .boardlist__add {
    padding-left: 14px;
  }
}
