.boardlist {
  padding: 25px 0px 0px 113px;
  transition: background-color 0.85s ease;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #bbb;
  // DEV NOTE
  // Don't use 'flex-wrap: wrap' because that will prevent dragging items
  // onto the second row
  min-height: 260px;
  position: relative;
}

.boardlist--card-drag-over {
  background-color: rgb(255, 225, 150);
}

.boardlist--list-drag-over {
  background-color: #aaaaaa;
}

.boardlist__heading-container {
  position: absolute;
  box-sizing: border-box;
  transform: rotate(90deg) translateY(-109px);
  transform-origin: left top;
  left: 0px;
  top: 0px;
  width: 260px;
  background: #eeeeee;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.boardlist__draghandle {
  height: 100%;
  width: 30px;
}

.boardlist__heading {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  margin: 1px;
  padding: 1rem;
  width: 153px;
  font-weight: 600;
  min-height: 70px;
  text-align: center;
  text-shadow: 0px 0px 7px #fff, 0px 0px 8px #fff, 0px 0px 9px #fff,
    0px 0px 10px #fff, 0px 0px 11px #fff, 0px 0px 12px #fff, 0px 0px 13px #fff,
    0px 0px 14px #fff, 0px 0px 15px #fff;
  cursor: pointer;
  // DEV NOTE
  // Don't add a background because there's a drop target effect that
  // we don't want to interfere with

  // DEV NOTE 2
  // the above comment seems to be outdated
  background-color: #eee;
  border: 1px solid #eee;
  border-radius: 5px;
}

.boardlist__add {
  font-size: 20px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: solid 1px #555;
  cursor: pointer;
  background: #eee;
  padding-bottom: 4px; //for + alignment
}

.boardlist__add:focus,
.boardlist__add:active {
  border: none;
  outline: none;
  background: #333;
  color: #fff;
}

.boardlist__add:hover {
  background: #ddd;
}

@media print {
  .boardlist {
    background: none;
    min-width: 1000px !important; //overwriting the min-width assigned in the beautiful-dnd lib
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 16rem; // needs to be taller than the list heading text
    // avoid page breaks within lists
    page-break-inside: avoid; // old browsers
    break-inside: avoid; // newer browers
    min-height: 0;
    padding: 50px 0px 0px 0px;
    position: relative;
  }

  .boardlist + .boardlist {
    border-top: solid 1px black;
  }

  .boardlist__heading {
    width: 100%;
    min-height: 0;
  }

  .boardlist__add {
    display: none;
  }

  .boardlist__heading-container {
    transform: none;
    -webkit-transform: none;
    height: 60px;
    top: 0;
  }
}
