.indicator-list {
  display: block;
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: absolute;
  bottom: 0px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.7em;
  z-index: 2;
  background: white;

  @media print {
    display: none;
  }
}
