.field {
  display: block;
  margin: 0px 0px 1rem 0px;

  font-size: 1rem;
}

.field--readonly {
  border: 1px solid black;
}
.field__editable {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 0;
  background: transparent;
}
.field__editable--textarea {
  height: 100%;
}

.field__label {
  display: block;
  font-weight: bold;

  margin: 0.5rem 0px 0.4rem 0px;
}

.field-padding {
  padding: 0.4em 0.7em;
}
