.toolbar__button {
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #000;
  color: #fff;
  font-weight: 600;
  padding-right: 1rem;
  padding-left: 1rem;
  border: none;
  border-left: solid 1px #666;
  // vertical-align: middle;
  text-align: center;
  cursor: pointer;
  height: 100%;
  min-width: 90px;
}
.toolbar__button--dropdown {
  min-height: 40px;
  width: 100%;
  margin-bottom: 1px;
}

.toolbar__button:hover {
  background: #333;
}

.toolbar__button--warning {
  background-color: #ff0000;
}

.toolbar__button--warning:hover {
  background: #7b0101;
}

.toolbar__button--file {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 11px;
  cursor: pointer;
}

.toolbar__button--title {
  width: 100%;
  color: black;
  background: transparent;
  text-align: left;
  font-size: 1.5rem;
}

.toolbar__button--title:hover {
  background: #e3e3e3;
}

input[type="file"] {
  display: none;
}

.toolbar__dropdown {
  position: relative;
  display: inline-block;
}

.toolbar__dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding-top: 1px;
  z-index: 1;
}

.toolbar__dropdown {
  &:hover {
    .toolbar__dropdown-content {
      display: block;
    }
  }
}

[data-reach-menu-item][data-selected] {
  background: hsl(211, 81%, 36%);
  color: white;
  outline: none;
}

[data-reach-menu-button] {
  color: white;
  display: flex;
  position: relative;
  &::before {
    position: absolute;
    font-size: 15px;
    content: "▾";
    right: 10px;
    top: 18px;
  }
}
[data-reach-menu-button][aria-expanded="true"] {
  background: #333333;
  color: white;
  &::before {
    transform: rotate(180deg);
  }
}
