html {
  scroll-behavior: smooth;
}

body {
  background: #eee;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
}

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.row--right {
  text-align: right;
  &::after {
    content: "";
    display: table;
    clear: both;
  }
  .row__item {
    float: right;
  }
}

$flexboxgrid-columns: 12;
@import "./breakpoints";
@import "./grid/flexboxgrid";
