.printable {
  padding-bottom: 20px;
  border-bottom: 2px solid black;
  display: none;
}

@media print {
  .printable {
    display: block;
    margin: 30px;
  }
}

.printable__text {
  padding-bottom: 10px;
}
.printable__label {
  font-weight: bold;
}


