.field__button {
  background: #000;
  color: #fff;
  padding: 0.7em 1em;
  border: 3px solid transparent;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    cursor: auto;
    opacity: 0.2;
  }
  &:hover {
    background: #333;
  }
}
.field__button--border {
  background: white;
  border: 3px solid black;
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}

.field__button--has-icon {
  background: none;
  color: black;
  text-decoration: underline;
  &::before {
    margin-right: 0.7em;
  }
  &:hover {
    background-color: transparent;
  }
}
.field__button--has-icon-delete {
  // https://yoksel.github.io/url-encoder/
  padding: 0;
  &::before {
    width: 13px;
    height: 17px;
    content: url("data:image/svg+xml,%3Csvg width='13' height='17' viewBox='0 0 13 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.00415 4.59053L2.99594 4.40945L3.91326 14.5H9.08683L10.0042 4.40945L11.9959 4.59053L10.9133 16.5H2.08683L1.00415 4.59053Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 5H0V3H13V5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 0.5H9V3.5H7V2.5H6V3.5H4V0.5Z' fill='black'/%3E%3C/svg%3E%0A");
  }
}
.field__button--has-icon-add {
  padding: 0;
  &::before {
    width: 21px;
    height: 17px;
    content: url("data:image/svg+xml,%3Csvg  viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.5 15C12.0899 15 15 12.0899 15 8.5C15 4.91015 12.0899 2 8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15ZM8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z' fill='black'/%3E%3Cpath d='M7.65002 4.25H9.35002V12.75H7.65002V4.25Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.65002 4.25H9.35002V12.75H7.65002V4.25Z' fill='black'/%3E%3Cpath d='M12.75 7.64999L12.75 9.34999L4.25 9.34999L4.25 7.64999L12.75 7.64999Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.75 7.64999L12.75 9.34999L4.25 9.34999L4.25 7.64999L12.75 7.64999Z' fill='black'/%3E%3C/svg%3E%0A");
  }
}
.field__button--has-icon-download {
  &::before {
    width: 21px;
    height: 21px;
    content: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 14V14.875C3.5 15.5712 3.77656 16.2389 4.26884 16.7312C4.76113 17.2234 5.42881 17.5 6.125 17.5H14.875C15.5712 17.5 16.2389 17.2234 16.7312 16.7312C17.2234 16.2389 17.5 15.5712 17.5 14.875V14M14 10.5L10.5 14M10.5 14L7 10.5M10.5 14V3.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
.field__button--level-warning {
  background-color: #cf0000;
  color: #fff;
  text-decoration: none;
  padding: 0.7em 1em;
  &.field__button--has-icon-delete {
    // https://yoksel.github.io/url-encoder/

    &::before {
      width: 13px;
      height: 17px;
      content: url("data:image/svg+xml,%3Csvg width='13' height='17' viewBox='0 0 13 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.00415 4.59053L2.99594 4.40945L3.91326 14.5H9.08683L10.0042 4.40945L11.9959 4.59053L10.9133 16.5H2.08683L1.00415 4.59053Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 5H0V3H13V5Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 0.5H9V3.5H7V2.5H6V3.5H4V0.5Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
  &:hover {
    background-color: #a00000;
  }
}
.field__button--level-confirm {
  background-color: #28a745;
  color: #fff;
  text-decoration: none;
  &:hover {
    background-color: #008334;
  }
}
.field__button--success {
  background: #28a745;
  &:hover {
    background-color: #008334;
  }
}

.field__button--right {
  float: right;
}
