.colorpicker {
  position: relative;
  width: 14em;
  height: 13em;
}

.map {
  position: absolute;
  top: 1em;
  bottom: 1em;
  right: 2em;
  left: 1em;
  overflow: hidden;
  user-select: none;
  border-radius: 0.25em;

  &.active {
    cursor: none;
  }

  &.dark .pointer {
    border-color: #fff;
  }

  &.light .pointer {
    border-color: #000;
  }

  .pointer {
    position: absolute;
    width: 10px;
    height: 10px;
    margin-left: -5px;
    margin-bottom: -5px;
    border-radius: 100%;
    border: 1px solid #000;
    will-change: left, bottom;
  }

  .background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .background:before,
  .background:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .background:after {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .background:before {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.slider {
  position: absolute;
  user-select: none;

  &.vertical {
    top: 0;
    bottom: 0;
    left: 50%;
    width: 10px;
    cursor: ns-resize;

    .track {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 8px;
      margin-left: -4px;
    }
  }

  &.horizontal {
    left: 0;
    right: 0;
    top: 50%;
    height: 10px;
    cursor: ew-resize;

    .track {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 8px;
      margin-top: -4px;
    }
  }

  .track {
    border-radius: 3px;
    background: #888;
  }

  .pointer {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    margin-bottom: -8px;
    border-radius: 16px;
    background: #fff;
    box-shadow: inset 0 0 0 1px #ccc, 0 1px 2px #ccc;
    will-change: left, bottom;
  }
}

.hue-slider {
  position: absolute;
  top: 1em;
  bottom: 1em;
  right: 1.3em;

  .track {
    background: linear-gradient(
      to bottom,
      #ff0000 0%,
      #ff0099 10%,
      #cd00ff 20%,
      #3200ff 30%,
      #0066ff 40%,
      #00fffd 50%,
      #00ff66 60%,
      #35ff00 70%,
      #cdff00 80%,
      #ff9900 90%,
      #ff0000 100%
    );
  }
}

.opacity-slider {
  position: absolute;
  bottom: 1.3em;
  left: 1em;
  right: 2em;

  .slider {
    background: #fff
      url("data:image/gif;base64,R0lGODdhEAAQAPEAAMvLy8zMzP///wAAACwAAAAAEAAQAEACHYxvosstCAEMrq6Jj812Y59NIDQipdY5XLWqH4sVADs=")
      repeat;
    background-size: 8px 8px;
    height: 8px;

    .track {
      height: 8px;
      margin-top: -4px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #fff 100%
      );
    }
  }
}

.with-opacity-slider {
  height: 14em;

  .map {
    bottom: 2em;
  }

  .hue-slider {
    bottom: 2em;
  }
}
