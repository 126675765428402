.modal-container {
  padding: 0.4rem 4rem 0.4rem 2rem;
  max-width: 600px;
}

.modal__close {
  background: #000;
  color: #fff;
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  font-size: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
}

.modal__close:hover {
  background: #333;
}

.modal__heading {
  margin: 0px 4rem 1rem 0px;
}
